import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Row, Col } from 'reactstrap'

import ArticleAboutModal from './article-about-modal'
import {
  ArticleWrapper,
  ArticleContainer,
  ArticlePersonWrapper,
  ArticlePersonPhotoColWrapper,
  ArticlePersonPhotoWrapper,
  ArticlePersonAboutColWrapper,
  ArticlePersonDescColWrapper,
  ArticlePersonDescWrapper,
} from '../elements'

const Article = (props) => {
  const data = useStaticQuery(graphql`
    query {
      Article1Image1: file(relativePath: { eq: "parent/article7/image1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image2: file(relativePath: { eq: "parent/article7/image2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <ArticleWrapper>
      <ArticleContainer>
        <ArticlePersonDescWrapper>
          <Row className="marginTop90">
            <Col xs={12}>
              <p>
                Dlatego BNP Paribas rusza z projektem społecznym Misja
                Kieszonkowe. Eksperci z Kapituły programu (specjaliści z zakresu
                ekonomii, socjologii, psychologii i finansów) odpowiadają w nim
                na najbardziej nurtujące rodziców pytania i przekonują, że
                kieszonkowe to… inwestycja w przyszłość juniora.
              </p>
            </Col>
            <Col xs={12}>
              <p>
                Dawać kieszonkowe czy nie dawać – to dylemat, przed którym wciąż
                staje wielu polskich rodziców. Jak wynika z badania BNP Paribas
                (2022)<sup>1</sup>, co najmniej 65% rodziców daje regularne,
                stałe kieszonkowe swoim dzieciom. I słusznie. Bo jak zauważają
                eksperci,{' '}
                <strong>kieszonkowe to najtańsza lekcja zarządzania.</strong>{' '}
                Uczy dzieci podejmowania własnych decyzji finansowych i pozwala
                odczuć ich konsekwencje w mikroskali – a to z kolei przekłada
                się na odpowiedzialne gospodarowanie pieniędzmi w przyszłości.
              </p>
            </Col>
            <Col xs={12} lg={6} className="ArticlePersonDescWrapperFlexVC">
              <p>
                Dziecko, które nauczy się zarządzania małymi kwotami
                udostępnionymi przez rodziców, nie popełni błędów, zarządzając
                kiedyś własnymi dużymi pieniędzmi. Mało który rodzic zdaje sobie
                sprawę z tego, że kieszonkowe to inwestycja, dzięki której
                dziecko będzie bogatsze w przyszłości. Nic tak nie czyni ludzi
                oszczędnymi i rozsądnymi finansowo jak dobre przyzwyczajenia –
                podkreśla Maciej Samcik, dziennikarz ekonomiczny i autor bloga
                Subiektywnie o Finansach.
              </p>
              <p>
                Psychoterapeutka i publicystka Tatiana Mindewicz-Puacz zauważa,
                że warto traktować kieszonkowe jako narzędzie do trenowania
                przydatnych życiowo kompetencji. Nie tylko tych ściśle
                związanych z finansami, ale także umiejętności komunikacji,
                myślenia przyczynowo-skutkowego, negocjacji, a nawet… empatii.
                Jeśli masz pieniądze – masz się czym podzielić. Pieniądze dają
                dziecku poczucie sprawczości i wolności – także wolność wyboru,
                czy wyda je na siebie, zaoszczędzi, czy dzięki nim pomoże innym.
                Nie odbierajmy tej szansy dzieciom – mówi Tatiana
                Mindewicz-Puacz.
              </p>
            </Col>
            <Col xs={12} lg={6}>
              <Img
                fluid={data.Article1Image1.childImageSharp.fluid}
                className="articleImage"
                alt="Czy dawanie kieszonkowego się opłaca?"
              />
            </Col>
            <Col xs={12} className="marginTop30">
              <p>
                Monika Sajkowska, prezeska Fundacji Dajemy Dzieciom Siłę, zwraca
                uwagę na sytuację rodzin, których na kieszonkowe dla dziecka po
                prostu nie stać. Ekspertka zaznacza, że – co prawda – im
                mniejsza kwota, tym trudniej nią zarządzać, ale rezygnacja z
                choćby symbolicznego kieszonkowego nie jest dobrym rozwiązaniem.
                <br />
                Jeśli wytłumaczymy dziecku rodzinny budżet, zrozumie, dlaczego
                dostaje do dyspozycji małą kwotę. Może będzie nią rozczarowane,
                ale doceni, że jest uwzględniane w rodzinnych finansach, a jego
                potrzeby są ważne, choć nie mogą być w pełni zaspokojone. Ważne,
                że dziecko ma swój i tylko swój obszar podejmowania decyzji.
                Zadaniem rodziców jest także umocnienie w nim przekonania, że
                trudna sytuacja finansowa rodziny to nie wstyd – przekonuje
                Monika Sajkowska.
              </p>
            </Col>
            <Col xs={12} sm={6}>
              <div className="imgWrapper">
                <Img
                  fluid={data.Article1Image2.childImageSharp.fluid}
                  className="articleImage"
                  alt="Czy dawanie kieszonkowego się opłaca?"
                />
              </div>
            </Col>
            <Col xs={12} sm={6} className="ArticlePersonDescWrapperFlexVC">
              <p>
                <strong>
                  Specjaliści z Kapituły programu Misja Kieszonkowe wiedzą
                  zgodnie, że kieszonkowe się opłaca. Jeśli chcesz posłuchać ich
                  praktycznych porad i dowiedzieć się, co robić, gdy młody
                  szasta kasą, jak rozmawiać o pieniądzach i nie przynudzać albo
                  jak nie wychować małego materialisty, koniecznie zajrzyj do
                  strefy rodzica na{' '}
                  <a href="/misjasamodzielnosc/strefa-rodzica">
                    https://www.bnpparibas.pl/misjasamodzielnosc/strefa-rodzica
                  </a>
                  .
                </strong>
              </p>
            </Col>
            <Col xs={12} className="marginTop30">
              <p>Materiał przygotowany we współpracy z członkami Kapituły.</p>
            </Col>
            <Col xs={12} className="marginTop30 small">
              <p>
                <sup>1</sup> BNP Paribas, Badanie postaw rodziców wobec
                kieszonkowego. Raport z badania jakościowo-ilościowego, 2022.
              </p>
            </Col>
          </Row>
        </ArticlePersonDescWrapper>
      </ArticleContainer>
    </ArticleWrapper>
  )
}

export default Article
