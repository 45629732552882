import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ArticleHeader from '../../components/parent/article-header'
import Article7 from '../../components/parent/article7'
import UsefulTools from '../../components/parent/useful-tools'

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      ArticleHeaderImage1: file(
        relativePath: { eq: "parent/article7/article-header7.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1110) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool1: file(relativePath: { eq: "parent/tools/gry.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool2: file(relativePath: { eq: "parent/tools/krzyzowki.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout location={location}>
      <SEO
        title="Czy dawanie kieszonkowego się opłaca?"
        description="Jasne, że się opłaca! Bo jak inaczej nauczyć dziecko wartości pieniądza, gospodarowania budżetem i oszczędzania, jeśli nie w praktyce? Świat potrzebuje samodzielnych dzieciaków, które rozumieją świat finansów, ale wokół kieszonkowego narosło wiele mitów i fałszywych przekonań."
        linkImage={data.ArticleHeaderImage1.childImageSharp.fluid.src}
      />

      <ArticleHeader
        image={data.ArticleHeaderImage1.childImageSharp.fluid}
        date="12.04.2021"
        title={<>Czy dawanie kieszonkowego się opłaca?</>}
        desc={
          <p>
            Jasne, że się opłaca! Bo jak inaczej nauczyć
            <br />
            dziecko wartości pieniądza, gospodarowania
            <br />
            budżetem i{'\u00A0'}oszczędzania, jeśli nie w{'\u00A0'}praktyce?
            <br />
            Świat potrzebuje samodzielnych dzieciaków,
            <br />
            które rozumieją świat finansów, ale wokół
            <br />
            kieszonkowego narosło wiele mitów
            <br />i{'\u00A0'}fałszywych przekonań.
          </p>
        }
        linkPrev="/strefa-rodzica/baza-wiedzy"
        linkNext="/strefa-rodzica/baza-wiedzy#narzedzia"
      />
      <Article7 />
      <UsefulTools
        items={[
          {
            title: 'Krzyżówki',
            subtitle: 'Krzyżówka 2',
            image: data.Tool2,
            link: '/parent/Krzyzowka%202.pdf',
          },
          {
            title: 'Gry',
            subtitle: 'Budżet Samodzielniaka / Plansza i instrukcja',
            image: data.Tool1,
            link: '/parent/Budzet_Samodzielniaka_plansza_instrukcja.pdf',
          },
          {
            title: 'Gry',
            subtitle: 'Bankosłówka',
            image: data.Tool1,
            link: '/parent/Bankoslowka.pdf',
          },
        ]}
      />
    </Layout>
  )
}

export default IndexPage
